import React from 'react';
import { graphql } from 'gatsby'
import Layout from '../components/Layout';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { MDXRenderer } from "gatsby-plugin-mdx";

const useStyles = makeStyles(theme => ({
  title: {
    width: "100%",
    textAlign: "center"
  },
  subContainer: {
    margin: `${theme.spacing(6)}px 0px`,
    // alignSelf: "start",
    "& p" :{
      textAlign: "start !important",
      fontWeight: 500,
      lineHeight: 1.75,
    },
    "& h3": {
      margin: `${theme.spacing(8)}px 0px 0px`,
      [theme.breakpoints.down('sm')]: {
        margin: `${theme.spacing(0)}px 0px 0px`,
      },
    },
    "& h6": {
      margin: `${theme.spacing(4)}px 0px 0px`,
    },
  },
}));
const FaqPage = ({ data: { allMdx : { edges } } }) => {
  const classes = useStyles();
  return (
    <Layout pageName="faq">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.title} variant="h1">Questions fréquentes</Typography>
        {edges.map(({
        node: {
          frontmatter : { 
            title, 
          },
          body,
        }
      }, i) =>
        <Grid
          item
          xs={12} sm={10} md={8} lg={6} xl={5} 
          className={classes.subContainer}
          index={`${title}-${i}`}
        >
          <MDXRenderer className={classes.containerText}>{body}</MDXRenderer>
        </Grid>
      )}
      </Grid>
    </Layout>
  );
};
export const faqQuery = graphql`
query faqQuery {
  allMdx(filter: {fileAbsolutePath: {regex: "/content/faq/"}}, sort: {fields: frontmatter___question}) {
    edges {
      node {
        body
        frontmatter {
          title
        }
      }
    }
  }
}
`
export default FaqPage;
